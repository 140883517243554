<template>
  <div class="content">
    <vue-element-loading :active="show" spinner="bar-fade-scale" color="#FF6700" :text="showText" :is-full-screen="true" />
    <TheNav :navLinkList="navLinkList" :classifyList="classifyList" />

    <div class="news-content">
        <div class="news-left">
          <div class="news-main">
              <div class="news-title size-16">{{ details.newsTitle  }}</div>
              <div class="news-desc">
                <div class="desc-item desc-item-left"><span>文章出处：</span><span>{{ details.articleSource }}</span></div>
                <div class="desc-item desc-item-more"><span>责任编辑：</span><span>{{ details.editor }}</span></div>
                <div class="desc-item desc-item-right"><span>发表时间：</span><span>{{ details.createTime }}</span></div>
              </div>
              <div class="ql-snow"><div class="info-detail ql-editor" v-html="details.newsContent"></div></div>
          </div>
          <div class="news-more">
            <div class="more-left" @click="prev(details)">
              <div class="left-pointer"></div>
              <span>上一篇：</span><span>{{ details.previousNewsTitle }}</span>
            </div>
            <div class="more-right" @click="next(details)">
              <span>下一篇：</span><span>{{ details.nextNewsTitle }}</span>
              <div class="right-pointer"></div>
            </div>
          </div>
          <router-link :to="{name:'News'}">
            <div class="back-list"><i class="fa fa-long-arrow-left"></i>返回列表</div>
          </router-link>
        </div>

        <div class="news-side" v-if="newsList.length > 0">
          <div class="side-item side-item-left">
            <div class="side-title size-14">{{ newsList[0].newsTypeName }}</div>
            <div class="side-content">
              <div class="side-content-item" v-for="(item,key) in newsList[0].newList" :key="key">
                <router-link :to="{name:'NewsDetail',params:{newsId:item.id}}">
                  <i class="fa fa-newspaper-o"></i>
                  <span class="item-text">{{ item.newsTitle }}</span>
                </router-link>
              </div>
            </div>
          </div>
          <div class="side-item side-item-right" v-if="newsList.length > 1">
            <div class="side-title size-14">{{ newsList[1].newsTypeName }}</div>
            <div class="side-content">
              <div class="side-content-item" v-for="(item,key) in newsList[1].newList" :key="key">
                <router-link to="/news/1">
                  <i class="fa fa-newspaper-o"></i>
                  <span class="item-text">{{ item.newsTitle }}</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div style="clear:both;"></div>
    </div>
  </div>
</template>

<script>
import { getNewsNavList } from "@/utils/common";
import { getNewsDetail,getNewsList } from "@/api/news";

export default {
  name: 'NewsDetail',
  computed: {
    // 当前页，从查询参数 page 返回
    currentPage() {
      return parseInt(this.$route.query.page) || 1
    }
  },
  data() {
    return {
      params:{
        id:''
      },
      newsParams:{
        page:1,
        limit:10
      },
      classifyList:[],
      navLinkList:[],
      details:{ },
      newsList:[],
      show:false,
      showText:''
    }
  },
  created(){
    this.init()
  },
  watch: {
	// 监听路由是否变化
	'$route' (to) { 
      if(to.params.newsId != this.params.id){
        this.params.id=to.params.newsId;// 把最新id赋值给定义在data中的id
        this.init(); // 重新调用加载数据方法
      }
    },
  },
  methods: {
    init() {
      this.params.id = parseInt(this.$route.params.newsId)
      this.navLinkList = getNewsNavList() 
      this.getNewsDetail()
    },
    getNewsDetail() {
      this.show = true;
      this.showText = "加载中...";
      getNewsDetail(this.params)
        .then(res => {
          if (res.code !== 1) {
            console.log(res.msg);
          } else {
            this.details = res.data
            this.details.createTime = this.details.createTime.substring(0,10)
            // let that = this
            this.classifyList = []
            this.newsList = []
              getNewsList(this.newsParams)
              .then(res => {
                if (res.code !== 1) {
                  console.log(res.msg);
                } else {
                  let info,id
                  res.data.forEach((item) =>  {
                    id = item.id
                    info = {
                      id:item.id,
                      name: item.newsTypeName,
                      link:{name:'NewsListParams',params:{newsId:id}},
                      isActive:item.id == this.details.newsTypeId ? true : false
                    }

                    if(item.id != this.details.newsTypeId) {
                      this.newsList.push(item)
                    }
                    this.classifyList.push(info)
                  })

                  let chosseNav = this.classifyList.find(item => {return item.id == this.details.newsTypeId})
                  this.navLinkList.push({name:chosseNav.name,link:''})
                }
            this.show = false;
          }).catch(err => {
              console.log(err);
              this.show = false;
            });
            
            // this.navLinkList.push({name:this.detail.productName,link:''})
          }
        })
        .catch(err => {
          console.log(err);
          this.show = false;
        });
      },
      prev(info) {
        if(info.previousId > 0) {
          this.$router.push({name:'NewsDetail',params:{newsId:info.previousId} })
        }else {
          return
        }
      },
      next(info) {
        if(info.nextId > 0) {
          this.$router.push({name:'NewsDetail',params:{newsId:info.nextId} })
        }else {
          return
        }
      }
  }
}
</script>

<style scoped>
.news-content {
  margin-bottom:40px; 
  padding:20px 10%;
  background-color: #f9f9f9;
}

.nav-collpose {
  background-color: #fff;
}

.news-left {
  width:74%;
  background-color: #fff;
  float: left;
  position: relative;
}

.news-main {
  padding:20px;
  border-bottom:1px solid #e7e7e7;
}

.news-title {
  margin:10px 0px;
}

.news-desc {
  padding-bottom:10px;
  border-bottom:1px solid #e7e7e7;
}

.desc-item {
  display: inline-block;
  color:#999999;
}

.desc-item-left {
  padding-right:4px;
  border-right:1px solid #e7e7e7;
}

.desc-item-more {
  padding:0px 4px;
  border-right:1px solid #e7e7e7;
}

.desc-item-right {
  padding:0px 4px;
}

.info-detail {
  padding:30px 0px;
}

.news-more {
  padding:20px;
  display: flex;
}

.news-more .more-left{
  width: 50%;
  display: inline-block;
  position: relative;
  padding-left:10px;
  cursor: pointer;
}

.news-more .more-left:hover {
  color:#0094de;
}

.left-pointer {
  width: 6px;
  height: 6px;
  position: absolute;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  -webkit-transform: translate(-50%, -50%) rotate(-135deg);
  transform: translate(170%, 170%) rotate(45deg);
  left:-12px;
  cursor: pointer;
  top:-7px;
}

.news-more .more-right{
  width: 50%;
  display: inline-block;
  text-align: right;
  position: relative;
  padding-right:10px;
  cursor: pointer;
}

.news-more .more-right:hover {
  color:#0094de;
}

 .right-pointer {
    width: 6px;
    height: 6px;
    position: absolute;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    transform: translate(170%, 170%) rotate(-135deg);
    right:10px;
    top:-7px;
    cursor: pointer;
  }

  .news-side {
    width:25%;
    margin-left:1%;
    display: inline-block;
  }

  .side-item {
    background-color: #fff;
    padding:20px;
    margin-bottom:10px;
  }

  .side-item .side-title {
    padding-bottom: 10px;
    border-bottom:1px solid #e7e7e7;
  }

  .side-content-item {
    overflow: hidden; 
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin:10px 0px;
    cursor: pointer;
  }

  .item-text {
    padding-left:4px;
  }

  .back-list {
    position: absolute;
    top:0;
    left:-36px;
    /* width:16px; */
    writing-mode:vertical-rl;
    border:1px solid #e5e5e5;
    padding:10px;
    text-align: center;
    color:#999999;
  }

  .back-list:hover{
     color:#0094de;
  }

  .content /deep/ .link-row-li a {
    background-color: #fff !important;
  }

@media (max-width: 1360px) {
.news-left {
  width:100%;
  float: none;
}

.news-side {
  width:100%;
  margin-left:0;
  display: flex;
  margin-top:10px;
}

.side-item {
  display: inline-block;
  width:49%;
}

.side-item-left {
  margin-right:2%;
}

}

@media (max-width: 768px) {
  .news-content {
    padding:10px 10px 10px 10px;
  }
  .side-item {
  display: inline-block;
  width:100%;
}

.side-item-left {
  margin-right:0;
}

.back-list {
  position: relative;
  /* top:-43px; */
  left:0;
  /* width:16px; */
  writing-mode:initial;
  border:0;
  border-top:1px solid #e5e5e5;
  padding:10px;
  text-align: center;
  color:#999999;
}

.desc-item {
  display: block;
  color:#999999;
}

.desc-item-left {
  padding-right:0px;
  border-right:0;
}

.desc-item-more {
  padding:0px;
  border-right:0;
}

.desc-item-right {
  padding:0px;
}

}


</style>